<template>
  <div class="employees">
    <div class="employees__title">
      <PageTitle text="Пользователи" />
      <el-select
        class="employees__title-select"
        v-if="!currentCompanyOfUser"
        v-model="selectedCompany"
        filterable
        placeholder="Выберите компанию"
        @change="filterByCompany"
      >
        <el-option
          v-for="item in companiesAllList"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        />
      </el-select>
      <div class="employees__title-options">
        <el-button type="primary" @click="addEmployee">
          <span class="hide-xs">+ </span>Добавить
          <span class="hide-xs">работника</span>
        </el-button>
      </div>
    </div>

    <div class="employees__table">
      <el-table
        :data="employeesList"
        :row-class-name="tableRowClassName"
        v-loading="loading"
      >
        <el-table-column :index="0">
          <template slot="header">
            <div>
              Полное имя
            </div>
          </template>
          <template slot-scope="scope">
            <div class="employees__table-item">
              <span
                class="employees__table-item--bold"
                @click="getDetailEmployee(scope.row.id)"
              >
                {{ scope.row.name }}
              </span>
              <template v-if="tooltipText(scope.row).length">
                <span
                  @click="showExpiration"
                  class="el-tooltip employees__tooltip  hide-lg  hide-md  hide-sm"
                  tabindex="0"
                  >!</span
                >
                <el-tooltip
                  class="employees__tooltip  hide-xs"
                  effect="light"
                  placement="bottom"
                >
                  <div slot="content" v-html="tooltipText(scope.row)"></div>
                  <span>!</span>
                </el-tooltip>
              </template>
              <br />
              <span>
                {{ scope.row.phone }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="1">
          <template slot="header">
            E-mail
          </template>
          <template slot-scope="scope">
            <div class="professions__table-item">
              <a
                v-if="scope.row.email"
                :href="'mailto:' + scope.row.email"
                class="professions__table-email"
              >
                {{ scope.row.email }}
              </a>
              <span v-else class="color-muted">Нет информации</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="2">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Специальности
            </div>
            <el-select
              v-if="specialitiesFilterList && specialitiesFilterList.length"
              v-model="selectedSpeciality"
              filterable
              placeholder="Все специальности"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterBySpeciality"
            >
              <el-option
                v-for="item in specialitiesFilterList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
          <template slot-scope="scope">
            <div class="employees__table-item">
              <SpecialitiesExpand
                v-if="scope.row.speciality && scope.row.speciality.length"
                :list="scope.row.speciality"
              />
              <span v-else class="color-muted">Специальностей нет</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="3">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Проекты
            </div>
            <el-select
              v-if="projectsFilterList && projectsFilterList.length"
              v-model="selectedProject"
              filterable
              placeholder="Все проекты"
              class="el-select_filter"
              :key="scope.column.index"
              @change="filterByProject"
            >
              <el-option
                v-for="item in projectsFilterList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <div class="employees__table-item">
              <ul
                class="employees__table-list"
                v-if="scope.row.projects && scope.row.projects[0].length"
              >
                <li
                  class="employees__table-list-item"
                  v-for="(project, index) in scope.row.projects[0]"
                  :key="index"
                >
                  {{ project.title }}
                </li>
              </ul>
              <span v-else class="color-muted">В проектах не участвует</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="4" label="Статус">
          <template slot-scope="scope">
            <div class="employees__table-status__wrapper">
              <span>
                {{
                  scope.row.day_status === "work"
                    ? "Работает"
                    : scope.row.day_status === "pause"
                    ? "Перерыв"
                    : scope.row.day_status === "didnt start"
                    ? "Не работает"
                    : scope.row.day_status === "end"
                    ? "Отработал"
                    : ""
                }}
              </span>
              <div
                class="employees__table-status"
                :class="{
                  active: scope.row.day_status === 'work',
                  pause: scope.row.day_status === 'pause',
                  didnt_work: scope.row.day_status === 'didnt start',
                  finish: scope.row.day_status === 'end'
                }"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column :index="5" width="150" :header-align="'left'">
          <template slot="header" slot-scope="scope">
            <div class="table-th--title">
              Отклонение
            </div>
          </template>
          <template slot-scope="scope">
            <ul
              class="employees__table-list"
              v-if="scope.row.projects && scope.row.projects[0].length"
            >
              <li
                class="employees__table-list-item"
                v-for="(project, index) in scope.row.projects[0]"
                :key="index"
              >
                <el-tooltip
                  v-if="
                    scope.row.worktimes.find(el => el.project_id === project.id)
                  "
                  effect="light"
                  placement="bottom"
                >
                  <div
                    slot="content"
                    v-html="
                      getSchedule(
                        scope.row.worktimes.find(
                          el => el.project_id === project.id
                        )
                      )
                    "
                  />
                  <div class="employees__schedule-row">
                    <div class="employees__schedule-value">
                      н:
                      {{
                        getTimeUTM(
                          scope.row.worktimes.find(
                            el => el.project_id === project.id
                          ).start_date
                        )
                      }}
                    </div>
                    <div class="employees__schedule-value">
                      к:
                      {{
                        getTimeUTM(
                          scope.row.worktimes.find(
                            el => el.project_id === project.id
                          ).end_date
                        )
                      }}
                    </div>
                  </div>
                </el-tooltip>
                <span v-else class="color-muted">Время отсутствует</span>
              </li>
            </ul>
            <span v-else class="color-muted">&ndash;</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
      :index="5"
      width="136"
      :header-align="'left'"
      >
        <template slot="header" slot-scope="scope">
          <div class="table-th--title">
            График
          </div>
          <el-select
          v-if="workPlanFilterList && workPlanFilterList.length"
          v-model="selectedWorkPlan"
          filterable
          placeholder="Все статусы"
          class="el-select_filter"
          :key="scope.column.index"
          @change="filterByWorkPlan"
          >
            <el-option
            v-for="item in workPlanFilterList"
            :key="item.key_const"
            :label="item.name"
            :value="item.key_const"
            >
            </el-option>
          </el-select>
        </template>
        <template slot-scope="scope">
          <div class="employees__table-item">
            <ul
            class="employees__table-list"
            v-if="scope.row.projects && scope.row.projects[0].length"
            >
              <li
              class="employees__table-list-item"
              v-for="(project, index) in scope.row.projects[0]"
              :key="index"
              >
                <div v-if="project.plan" class="employees__table-status">
                  <span v-if="project.plan === 'NORMAL'" class="color-green">Норма</span>
                  <span v-else-if="project.plan === 'NOT_ENOUGH_TIME'" class="color-grey">Недоработка</span>
                  <span v-else-if="project.plan === 'DIDNT_START'" class="color-red">Не начал работу</span>
                  <span v-else class="color-muted">&ndash;</span>
                </div>
              </li>
            </ul>
            <span v-else class="color-muted">&ndash;</span>
          </div>
        </template>
      </el-table-column> -->
        <el-table-column :index="6" width="152">
          <template slot-scope="scope">
            <div class="employees__table-actions">
              <div
                v-if="user.role !== 'admin'"
                class="employees__table-actions__chat"
                @click="openChat(scope.row.id, scope.row.name, scope.row.image)"
              >
                <img
                  class="employees__table-actions__chat"
                  src="@/assets/icons/icon-chat.svg"
                  alt="chat"
                />
                <span
                  v-if="getCount(scope.row.id)"
                  class="employees__table-actions__chat-count"
                  v-text="getCount(scope.row.id)"
                />
              </div>
              <div class="more-options  hide-lg  hide-md">
                <span
                  @click="scope.row.visibleMoreOptions = true"
                  class="el-dropdown-link"
                >
                  <img src="@/assets/icons/icon-option.svg" />
                </span>
                <el-dialog
                  title="Действия"
                  :visible.sync="scope.row.visibleMoreOptions"
                  width="288px"
                >
                  <el-dropdown-item
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'more', id: scope.row.id });
                    "
                  >
                    <img src="@/assets/icons/icon-info.svg" alt="more" />
                    Подробнее
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({
                        name: 'blocked',
                        id: scope.row.id,
                        status: scope.row.is_blocked
                      });
                    "
                  >
                    <img
                      v-if="scope.row.is_blocked"
                      src="@/assets/icons/icon-unblock.svg"
                      alt="unblocked"
                    />
                    <img
                      v-else
                      src="@/assets/icons/icon-block-back.svg"
                      alt="blocked"
                    />
                    {{
                      scope.row.is_blocked ? "Разблокировать" : "Заблокировать"
                    }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native="
                      scope.row.visibleMoreOptions = false;
                      handleCommand({ name: 'delete', id: scope.row.id });
                    "
                    class="el-dropdown-menu__item removed"
                  >
                    <img
                      src="@/assets/icons/icon-removed-red.svg"
                      alt="remove"
                    />
                    Удалить
                  </el-dropdown-item>
                </el-dialog>
              </div>
              <el-dropdown
                class="hide-sm  hide-xs"
                trigger="click"
                @command="handleCommand"
              >
                <span class="el-dropdown-link">
                  <img src="@/assets/icons/icon-option.svg" alt="options" />
                </span>
                <el-dropdown-menu slot="dropdown" class="employees__options">
                  <el-dropdown-item
                    :command="{ name: 'more', id: scope.row.id }"
                  >
                    <img src="@/assets/icons/icon-info.svg" alt="more" />
                    Подробнее
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{
                      name: 'blocked',
                      id: scope.row.id,
                      status: scope.row.is_blocked
                    }"
                  >
                    <img
                      v-if="scope.row.is_blocked"
                      src="@/assets/icons/icon-unblock.svg"
                      alt="unblocked"
                    />
                    <img
                      v-else
                      src="@/assets/icons/icon-block-back.svg"
                      alt="blocked"
                    />
                    {{
                      scope.row.is_blocked ? "Разблокировать" : "Заблокировать"
                    }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{ name: 'delete', id: scope.row.id }"
                    class="el-dropdown-menu__item removed"
                  >
                    <img
                      src="@/assets/icons/icon-removed-red.svg"
                      alt="remove"
                    />
                    Удалить
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="employees__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
    <el-dialog :visible.sync="visibleModal" width="400px">
      <div class="change-tariff">
        <div class="change-tariff__title">
          Ваш тариф не поддерживает данную функцию
        </div>
        <router-link to="/tariffs" class="el-button">
          Сменить тариф
        </router-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";
import Pagination from "../components/blocks/Pagination";
import SpecialitiesExpand from "../components/elements/SpecialitiesExpand";

export default {
  name: "Employees",
  components: {
    PageTitle,
    Pagination,
    SpecialitiesExpand
  },
  data() {
    return {
      loading: false,
      currentCompany: null,
      selectedCompany: "",
      typeSort: "",
      ascOrder: true,
      page: 1,
      pageCount: 10,
      selectedSpeciality: "",
      selectedProject: "",
      selectedWorkPlan: "",
      showedMore: false,
      visibleModal: false
    };
  },
  computed: {
    user() {
      return this.$store.state.Auth.user;
    },
    companiesList() {
      return this.$store.state.Employee.companiesList;
    },
    companiesAllList() {
      let arr = [];
      if (this.companiesList) {
        arr = JSON.parse(JSON.stringify(this.companiesList));
        arr.unshift({ id: "", title: "Все компании" });
      }
      return arr;
    },
    specialitiesList() {
      return this.$store.state.Employee.specialitiesList;
    },
    specialitiesFilterList() {
      let arr = [];
      if (this.specialitiesList) {
        arr = JSON.parse(JSON.stringify(this.specialitiesList));
        arr.unshift({ id: "", name: "Все специальности" });
      }
      return arr;
    },
    projectsListAll() {
      return this.$store.state.Employee.projectsListAll;
    },
    projectsFilterList() {
      let arr = [];
      if (this.projectsListAll) {
        arr = JSON.parse(JSON.stringify(this.projectsListAll));
        arr.unshift({ id: "", title: "Все проекты" });
      }
      return arr;
    },
    workPlanList() {
      return this.$store.state.Employee.workPlanList;
    },
    employeesList() {
      return this.$store.state.Employee.employeesList?.map(item => ({
        ...item,
        visibleMoreOptions: false
      }));
    },
    pagination() {
      return this.$store.state.Employee.pagination;
    },
    sortOrder() {
      let order = "asc";
      if (!this.ascOrder) order = "desc";
      return order;
    },
    currentCompanyOfUser() {
      return this.$store.state.Auth.currentCompanyOfUser;
    },
    ready() {
      return this.$store.state.Auth.ready;
    },
    allMessages() {
      return this.$store.getters["Chat/allMessages"];
    },
    currentTariff() {
      return this.$store.getters["Tariffs/currentTariff"];
    },
    userRole() {
      return this.$store.state.Auth.userRole;
    }
  },
  watch: {
    ready(val) {
      if (val) {
        this.getEmployees();
        this.getFiltersLists();
      }
    }
  },
  created() {
    // Делать запрос на список Employees и
    // фильтры только, если данные пользователя получены и обработаны
    if (this.ready) {
      this.getFiltersLists();
      this.getEmployees();
    }
  },
  methods: {
    getCount(id) {
      let count = 0;
      if (this.allMessages && this.allMessages.length) {
        const elem = this.allMessages.find(el => el.id === id);
        if (elem) count = elem.count;
      }
      return count;
    },
    tableRowClassName({ row }) {
      if (row.is_blocked) {
        return "blocked-row";
      }
      return "";
    },
    getEmployees() {
      this.loading = true;
      if (this.currentCompanyOfUser) {
        this.currentCompany = this.currentCompanyOfUser;
      }
      const params = {
        page: this.page,
        pageCount: this.pageCount,
        speciality: this.selectedSpeciality ? this.selectedSpeciality : null,
        project: this.selectedProject ? this.selectedProject : null,
        work_plan: this.selectedWorkPlan ? this.selectedWorkPlan : null,
        sortField: this.typeSort ? this.typeSort : null,
        sortBy: this.typeSort ? this.sortOrder : null,
        company: this.currentCompany ? this.currentCompany : null
      };
      this.$store.dispatch("Employee/getEmployees", params).then(() => {
        this.loading = false;
      });
    },
    changePage(page, count) {
      this.page = page;
      this.pageCount = count;
      this.getEmployees();
    },
    addEmployee() {
      if (
        this.pagination.totalItems >= this.currentTariff.user_count &&
        this.userRole !== "admin"
      ) {
        this.visibleModal = true;
      } else {
        this.$router.push({ name: "NewEmployee" });
      }
    },
    getDetailEmployee(id) {
      this.$router.push({
        name: "Employee",
        params: { id: id }
      });
    },
    handleCommand(command) {
      switch (command.name) {
        case "delete":
          this.removeEmployee(command.id);
          break;
        case "blocked":
          this.blockEmployee(command.id, command.status);
          break;
        case "more":
          this.$router.push({
            name: "Employee",
            params: { id: command.id }
          });
          break;
      }
    },
    showExpiration() {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-expiration.svg")}" alt="Истечение срока действия">
        <div>
          <div class="remove-message__title">
            Истечение срока действия
          </div>
          <div class="remove-message__text">
            Истекает срок медицинской страховки
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box  remove-message__box--warning",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Понятно",
          showClose: false
        }
      );
    },
    removeEmployee(id) {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}" alt="Удаление исполнителя">
        <div>
          <div class="remove-message__title">
            Удаление исполнителя
          </div>
          <div class="remove-message__text">
            Вы действительно хотите удалить запись об исполнителе из базы данных и все ее связи?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Удалить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        this.$store
          .dispatch("Employee/removeEmployee", id)
          .then(() => {
            this.getEmployees();
            this.$notify({
              title: "Успех",
              message: "Работник удален",
              type: "success"
            });
          })
          .catch(err => {
            console.error(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    },
    blockEmployee(id, status) {
      const data = { is_blocked: status === 0 ? 1 : 0 };
      this.$store
        .dispatch("Employee/blockEmployee", { id, data })
        .then(() => {
          this.getEmployees();
          this.$notify({
            title: "Успех",
            message:
              status === 0 ? "Работник заблокирован" : "Работник pазблокирован",
            type: "success"
          });
        })
        .catch(err => {
          console.error(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    getFiltersLists() {
      this.$store.dispatch("Employee/getCompaniesList").then(() => {
        if (this.$route.query.company) {
          this.selectedCompany = this.$route.query.company;
        }
      });
      this.$store.dispatch("Employee/getSpecialities");
      this.$store.dispatch("Employee/getProjectsAll", {
        user: this.currentCompanyOfUser ? this.user.id : null // user передавать, только если вход совершен под Подрядчиком (Компанией)
      });
      this.$store.dispatch("Employee/getWorkPlan");
    },
    filterByCompany(val) {
      this.currentCompany = val;
      this.page = 1;
      this.getEmployees();
    },
    filterBySpeciality() {
      this.page = 1;
      this.getEmployees();
    },
    filterByProject() {
      this.page = 1;
      this.getEmployees();
    },
    openChat(id, name, image) {
      this.$store.commit("Chat/setData", {
        label: "currentChat",
        data: {
          type: "user",
          id,
          name,
          image: process.env.VUE_APP_BASE_URL + image
        }
      });
      this.$store.commit("Option/setData", {
        label: "currentOption",
        data: "Chat"
      });
    },
    tooltipText({
      expiration_date_contract,
      expiration_date_medical,
      expiration_permission_to_work
    }) {
      let result = "";
      const date = new Date().getTime();
      if (expiration_date_contract) {
        const contractExpiration =
          new Date(expiration_date_contract).getTime() -
          3 * 24 * 60 * 60 * 1000;
        result +=
          contractExpiration <= date ? "Истекает срок контракта<br>" : "";
      }
      if (expiration_date_medical) {
        const medicalExpiration =
          new Date(expiration_date_medical).getTime() - 3 * 24 * 60 * 60 * 1000;
        result +=
          medicalExpiration <= date
            ? "Истекает срок медицинской страховки<br>"
            : "";
      }
      if (expiration_permission_to_work) {
        const permissionExpiration =
          new Date(expiration_permission_to_work).getTime() -
          3 * 24 * 60 * 60 * 1000;
        result +=
          permissionExpiration <= date
            ? "Истекает срок разрешения на работу"
            : "";
      }
      return result;
    },
    getSchedule(worktime) {
      return `
      <div class="employees__schedule-tooltip">
          <div class="employees__schedule-row">
            <div class="employees__schedule-name">График: </div>
            <div class="employees__schedule-value">н: ${this.getTime(
              worktime?.start_date_plan
            )}</div>
            <div class="employees__schedule-value">к: ${this.getTime(
              worktime?.end_date_plan
            )}</div>
          </div>
          <div class="employees__schedule-row">
            <div class="employees__schedule-name">Факт: </div>
            <div class="employees__schedule-value">н: ${this.getTimeUTM(
              worktime?.start_date
            )}</div>
            <div class="employees__schedule-value">к: ${this.getTimeUTM(
              worktime?.end_date
            )}</div>
          </div>
      </div>
      `;
    },
    getTimeUTM(date) {
      const h = new Date(date).getHours();
      const m = new Date(date).getMinutes();
      let time = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
      return date ? time : "--:--";
    },
    getTime(date) {
      return date ? date.substr(11, 5) : "--:--";
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.employees {
  $section: &;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    &-options {
      display: flex;
    }

    .el-button {
      margin-left: 2.4rem;
      text-decoration: none;
    }

    .el-select {
      width: 27.4rem;
    }
  }

  &__table {
    //.cell {
    //  width: 80%;
    //}

    &-status__wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
    }
    &-status {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      margin-left: 10px;
      &.active {
        background: $active;
      }
      &.pause {
        background: $pause;
      }
      &.didnt_work {
        background: $error;
      }
      &.finish {
        background: $error;
      }
    }

    .el-table th {
      padding: 0;
      vertical-align: top;

      .cell {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
      }
    }

    &-item {
      &--bold {
        font-weight: 600;
        color: $primary;
        cursor: pointer;

        &:hover {
          color: $text;
        }

        @at-root {
          #{$section} .blocked-row & {
            color: inherit;
          }
        }
      }
    }

    &-list {
      list-style: none;

      &-item {
        margin-bottom: 0.8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-status {
      &-icon {
        vertical-align: top;
        margin: -0.1rem 0 0.1rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 1.4rem;

      &__chat {
        margin-right: 2rem;
        margin-top: 0.7rem;
        cursor: pointer;
        position: relative;

        &-count {
          position: absolute;
          right: -1px;
          top: -2px;
          height: 1.3rem;
          min-width: 1.3rem;
          font-size: 0.9rem;
          line-height: 1.1rem;
          text-align: center;
          color: #fff;
          padding: 0.1rem 0.2rem 0 0.3rem;
          border-radius: 50%;
          background-color: #dd4a64;
        }
      }
    }
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }
}

.employees__tooltip {
  vertical-align: baseline;
  display: inline-block;
  text-align: center;
  width: 13px;
  height: 13px;
  color: #fff;
  border-radius: 50%;
  background: #dd4a64;
  margin-left: 4px;
  position: relative;
  top: -2px;
  font-size: 12px;
  line-height: 100%;
  cursor: pointer;
}

.employees__schedule {
  font-size: 14px;
  line-height: 150%;
  color: #31333b;
}
.employees__schedule-value {
  &:not(:last-child) {
    margin-right: 4px;
  }
}
.employees__schedule-item {
  &:not(:last-child) {
    margin-bottom: 13px;
  }
}
.employees__schedule-row {
  display: flex;
  align-items: center;
  cursor: default;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.employees__schedule-tooltip {
  font-size: 12px;
  line-height: 16px;
}
.employees__schedule-name {
  width: 50px;
  color: #676973;
}
.employees__title-select {
  margin-left: auto;
}

@media (min-width: $screen-md-min) {
  .professions__table-email {
    text-decoration: none;
    color: currentColor;
  }
}

@media (max-width: $screen-sm-max) {
  .employees {
    .employees__table {
      margin: 0 -16px;
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }

    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        &:nth-child(n) {
          border-radius: 0;
          width: calc(50% - 12px);
          margin-top: 16px;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }

        &:nth-child(6) {
          width: calc(50% + 12px);

          .cell {
            padding-left: 0;
          }
        }

        &:last-child,
        &:nth-child(2),
        &:nth-child(5) {
          display: none;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(7) {
          margin: 0 16px 4px;
          order: -15;
          width: 100%;
          background: none;

          .cell {
            padding: 0;
          }

          .table-th--title {
            display: none;
          }
        }

        svg {
          display: none;
        }
      }

      .el-select {
        width: 100%;
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
      }
      td {
        position: static;
        border-radius: 0;
        padding: 0;
        padding-right: 10px;
        border: none;

        &:nth-child(1) {
          width: calc(50% - 12px);
          order: -25;

          .employees__table-item--bold {
            color: #31333b;
          }
        }

        &:nth-child(6) {
          width: calc(50% - 12px);
          order: -20;
        }

        &:nth-child(3) {
          order: -10;
          font-size: 12px;
          line-height: 16px;
        }

        &:nth-child(3) {
          order: -5;
          width: 100%;
          padding-right: 0;
        }

        &:nth-child(4) {
          display: none;
        }

        &:nth-child(7) {
          position: relative;
          padding-right: 0;
          margin-left: auto;
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }
      .employees__table-actions__chat {
        margin-right: 16px;

        img {
          margin: 0;
          position: static;
        }
      }
    }

    .el-table__row.blocked-row {
      color: #b7b9c7;
      background: #fff1f5;
    }
  }

  .professions__table-email {
    font-size: 12px;
    line-height: 16px;
    color: #1199f0;
  }

  .employees__table-actions {
    padding: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .employees__title {
    flex-wrap: wrap;

    .employees__title-select {
      order: 10;
      width: 100%;
      margin-top: 16px;
    }

    .el-input__inner {
      height: 32px;
      font-size: 12px;
      line-height: 16px;
      padding-left: 12px;
    }

    .el-input__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
      right: 5px;
    }

    .el-button {
      color: #1199f0;
      background: none;
      border-radius: 0;
      padding: 0;
      border-bottom: 1px solid;
    }
  }
  .employees__table-list-item {
    margin-bottom: 0;
  }

  .employees {
    .el-table__body-wrapper {
      td:nth-child(1) {
        width: calc(50% - 12px);
      }

      td:nth-child(5) {
        order: -10;
        display: flex;
        padding: 0;
        width: 18px;
      }
    }
    .employees__table {
      &-status__wrapper {
        span {
          display: none;
        }
      }
    }
  }
}
</style>
