<template>
  <div v-if="list && list.length" class="specialities-expand">
    <template v-if="list.length > count && !all">
      <ul class="specialities-expand__list">
        <li
          class="specialities-expand__item"
          v-for="(item, index) in list.filter((i, idx) => idx < count)"
          :key="index"
        >
          {{ item.name }}
          <a
            v-if="index === count - 1"
            href="#"
            class="specialities-expand__more"
            @click.prevent="all = !all"
            v-text="`+${list.length - count}`"
          ></a>
        </li>
      </ul>
    </template>
    <template v-else-if="list.length > count && all">
      <ul class="specialities-expand__list">
        <li
          class="specialities-expand__item"
          v-for="(item, index) in list"
          :key="index"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="specialities-expand__collapse">
        <a
          href="#"
          class="specialities-expand__collapse-link"
          @click.prevent="all = !all"
        >
          Свернуть
        </a>
      </div>
    </template>
    <template v-else>
      <ul class="specialities-expand__list">
        <li
          class="specialities-expand__item"
          v-for="(item, index) in list"
          :key="index"
        >
          {{ item.name }}
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SpecialitiesExpandList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      all: false,
      count: 1,
    }
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.specialities-expand {
  &__list {
    list-style: none;
    margin-bottom: 1.2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    margin-bottom: 0.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__collapse {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
</style>
