var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employees"
  }, [_c('div', {
    staticClass: "employees__title"
  }, [_c('PageTitle', {
    attrs: {
      "text": "Пользователи"
    }
  }), !_vm.currentCompanyOfUser ? _c('el-select', {
    staticClass: "employees__title-select",
    attrs: {
      "filterable": "",
      "placeholder": "Выберите компанию"
    },
    on: {
      "change": _vm.filterByCompany
    },
    model: {
      value: _vm.selectedCompany,
      callback: function callback($$v) {
        _vm.selectedCompany = $$v;
      },
      expression: "selectedCompany"
    }
  }, _vm._l(_vm.companiesAllList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.title,
        "value": item.id
      }
    });
  }), 1) : _vm._e(), _c('div', {
    staticClass: "employees__title-options"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.addEmployee
    }
  }, [_c('span', {
    staticClass: "hide-xs"
  }, [_vm._v("+ ")]), _vm._v("Добавить "), _c('span', {
    staticClass: "hide-xs"
  }, [_vm._v("работника")])])], 1)], 1), _c('div', {
    staticClass: "employees__table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "data": _vm.employeesList,
      "row-class-name": _vm.tableRowClassName
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employees__table-item"
        }, [_c('span', {
          staticClass: "employees__table-item--bold",
          on: {
            "click": function click($event) {
              return _vm.getDetailEmployee(scope.row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.name) + " ")]), _vm.tooltipText(scope.row).length ? [_c('span', {
          staticClass: "el-tooltip employees__tooltip  hide-lg  hide-md  hide-sm",
          attrs: {
            "tabindex": "0"
          },
          on: {
            "click": _vm.showExpiration
          }
        }, [_vm._v("!")]), _c('el-tooltip', {
          staticClass: "employees__tooltip  hide-xs",
          attrs: {
            "effect": "light",
            "placement": "bottom"
          }
        }, [_c('div', {
          attrs: {
            "slot": "content"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.tooltipText(scope.row))
          },
          slot: "content"
        }), _c('span', [_vm._v("!")])])] : _vm._e(), _c('br'), _c('span', [_vm._v(" " + _vm._s(scope.row.phone) + " ")])], 2)];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('div', [_vm._v(" Полное имя ")])])], 2), _c('el-table-column', {
    attrs: {
      "index": 1
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "professions__table-item"
        }, [scope.row.email ? _c('a', {
          staticClass: "professions__table-email",
          attrs: {
            "href": 'mailto:' + scope.row.email
          }
        }, [_vm._v(" " + _vm._s(scope.row.email) + " ")]) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("Нет информации")])])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" E-mail ")])], 2), _c('el-table-column', {
    attrs: {
      "index": 2
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Специальности ")]), _vm.specialitiesFilterList && _vm.specialitiesFilterList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "filterable": "",
            "placeholder": "Все специальности"
          },
          on: {
            "change": _vm.filterBySpeciality
          },
          model: {
            value: _vm.selectedSpeciality,
            callback: function callback($$v) {
              _vm.selectedSpeciality = $$v;
            },
            expression: "selectedSpeciality"
          }
        }, _vm._l(_vm.specialitiesFilterList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.name,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employees__table-item"
        }, [scope.row.speciality && scope.row.speciality.length ? _c('SpecialitiesExpand', {
          attrs: {
            "list": scope.row.speciality
          }
        }) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("Специальностей нет")])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 3
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Проекты ")]), _vm.projectsFilterList && _vm.projectsFilterList.length ? _c('el-select', {
          key: scope.column.index,
          staticClass: "el-select_filter",
          attrs: {
            "filterable": "",
            "placeholder": "Все проекты"
          },
          on: {
            "change": _vm.filterByProject
          },
          model: {
            value: _vm.selectedProject,
            callback: function callback($$v) {
              _vm.selectedProject = $$v;
            },
            expression: "selectedProject"
          }
        }, _vm._l(_vm.projectsFilterList, function (item) {
          return _c('el-option', {
            key: item.id,
            attrs: {
              "label": item.title,
              "value": item.id
            }
          });
        }), 1) : _vm._e()];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employees__table-item"
        }, [scope.row.projects && scope.row.projects[0].length ? _c('ul', {
          staticClass: "employees__table-list"
        }, _vm._l(scope.row.projects[0], function (project, index) {
          return _c('li', {
            key: index,
            staticClass: "employees__table-list-item"
          }, [_vm._v(" " + _vm._s(project.title) + " ")]);
        }), 0) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("В проектах не участвует")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 4,
      "label": "Статус"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employees__table-status__wrapper"
        }, [_c('span', [_vm._v(" " + _vm._s(scope.row.day_status === "work" ? "Работает" : scope.row.day_status === "pause" ? "Перерыв" : scope.row.day_status === "didnt start" ? "Не работает" : scope.row.day_status === "end" ? "Отработал" : "") + " ")]), _c('div', {
          staticClass: "employees__table-status",
          class: {
            active: scope.row.day_status === 'work',
            pause: scope.row.day_status === 'pause',
            didnt_work: scope.row.day_status === 'didnt start',
            finish: scope.row.day_status === 'end'
          }
        })])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 5,
      "width": "150",
      "header-align": 'left'
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "table-th--title"
        }, [_vm._v(" Отклонение ")])];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [scope.row.projects && scope.row.projects[0].length ? _c('ul', {
          staticClass: "employees__table-list"
        }, _vm._l(scope.row.projects[0], function (project, index) {
          return _c('li', {
            key: index,
            staticClass: "employees__table-list-item"
          }, [scope.row.worktimes.find(function (el) {
            return el.project_id === project.id;
          }) ? _c('el-tooltip', {
            attrs: {
              "effect": "light",
              "placement": "bottom"
            }
          }, [_c('div', {
            attrs: {
              "slot": "content"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.getSchedule(scope.row.worktimes.find(function (el) {
                return el.project_id === project.id;
              })))
            },
            slot: "content"
          }), _c('div', {
            staticClass: "employees__schedule-row"
          }, [_c('div', {
            staticClass: "employees__schedule-value"
          }, [_vm._v(" н: " + _vm._s(_vm.getTimeUTM(scope.row.worktimes.find(function (el) {
            return el.project_id === project.id;
          }).start_date)) + " ")]), _c('div', {
            staticClass: "employees__schedule-value"
          }, [_vm._v(" к: " + _vm._s(_vm.getTimeUTM(scope.row.worktimes.find(function (el) {
            return el.project_id === project.id;
          }).end_date)) + " ")])])]) : _c('span', {
            staticClass: "color-muted"
          }, [_vm._v("Время отсутствует")])], 1);
        }), 0) : _c('span', {
          staticClass: "color-muted"
        }, [_vm._v("–")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "index": 6,
      "width": "152"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "employees__table-actions"
        }, [_vm.user.role !== 'admin' ? _c('div', {
          staticClass: "employees__table-actions__chat",
          on: {
            "click": function click($event) {
              return _vm.openChat(scope.row.id, scope.row.name, scope.row.image);
            }
          }
        }, [_c('img', {
          staticClass: "employees__table-actions__chat",
          attrs: {
            "src": require("@/assets/icons/icon-chat.svg"),
            "alt": "chat"
          }
        }), _vm.getCount(scope.row.id) ? _c('span', {
          staticClass: "employees__table-actions__chat-count",
          domProps: {
            "textContent": _vm._s(_vm.getCount(scope.row.id))
          }
        }) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "more-options  hide-lg  hide-md"
        }, [_c('span', {
          staticClass: "el-dropdown-link",
          on: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = true;
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg")
          }
        })]), _c('el-dialog', {
          attrs: {
            "title": "Действия",
            "visible": scope.row.visibleMoreOptions,
            "width": "288px"
          },
          on: {
            "update:visible": function updateVisible($event) {
              return _vm.$set(scope.row, "visibleMoreOptions", $event);
            }
          }
        }, [_c('el-dropdown-item', {
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'more',
                id: scope.row.id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg"),
            "alt": "more"
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'blocked',
                id: scope.row.id,
                status: scope.row.is_blocked
              });
            }
          }
        }, [scope.row.is_blocked ? _c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-unblock.svg"),
            "alt": "unblocked"
          }
        }) : _c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-block-back.svg"),
            "alt": "blocked"
          }
        }), _vm._v(" " + _vm._s(scope.row.is_blocked ? "Разблокировать" : "Заблокировать") + " ")]), _c('el-dropdown-item', {
          staticClass: "el-dropdown-menu__item removed",
          nativeOn: {
            "click": function click($event) {
              scope.row.visibleMoreOptions = false;

              _vm.handleCommand({
                name: 'delete',
                id: scope.row.id
              });
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg"),
            "alt": "remove"
          }
        }), _vm._v(" Удалить ")])], 1)], 1), _c('el-dropdown', {
          staticClass: "hide-sm  hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg"),
            "alt": "options"
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "employees__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'more',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg"),
            "alt": "more"
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'blocked',
              id: scope.row.id,
              status: scope.row.is_blocked
            }
          }
        }, [scope.row.is_blocked ? _c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-unblock.svg"),
            "alt": "unblocked"
          }
        }) : _c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-block-back.svg"),
            "alt": "blocked"
          }
        }), _vm._v(" " + _vm._s(scope.row.is_blocked ? "Разблокировать" : "Заблокировать") + " ")]), _c('el-dropdown-item', {
          staticClass: "el-dropdown-menu__item removed",
          attrs: {
            "command": {
              name: 'delete',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg"),
            "alt": "remove"
          }
        }), _vm._v(" Удалить ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "employees__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1), _c('el-dialog', {
    attrs: {
      "visible": _vm.visibleModal,
      "width": "400px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      }
    }
  }, [_c('div', {
    staticClass: "change-tariff"
  }, [_c('div', {
    staticClass: "change-tariff__title"
  }, [_vm._v(" Ваш тариф не поддерживает данную функцию ")]), _c('router-link', {
    staticClass: "el-button",
    attrs: {
      "to": "/tariffs"
    }
  }, [_vm._v(" Сменить тариф ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }