var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.list && _vm.list.length ? _c('div', {
    staticClass: "specialities-expand"
  }, [_vm.list.length > _vm.count && !_vm.all ? [_c('ul', {
    staticClass: "specialities-expand__list"
  }, _vm._l(_vm.list.filter(function (i, idx) {
    return idx < _vm.count;
  }), function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "specialities-expand__item"
    }, [_vm._v(" " + _vm._s(item.name) + " "), index === _vm.count - 1 ? _c('a', {
      staticClass: "specialities-expand__more",
      attrs: {
        "href": "#"
      },
      domProps: {
        "textContent": _vm._s("+".concat(_vm.list.length - _vm.count))
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          _vm.all = !_vm.all;
        }
      }
    }) : _vm._e()]);
  }), 0)] : _vm.list.length > _vm.count && _vm.all ? [_c('ul', {
    staticClass: "specialities-expand__list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "specialities-expand__item"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c('div', {
    staticClass: "specialities-expand__collapse"
  }, [_c('a', {
    staticClass: "specialities-expand__collapse-link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.all = !_vm.all;
      }
    }
  }, [_vm._v(" Свернуть ")])])] : [_c('ul', {
    staticClass: "specialities-expand__list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "specialities-expand__item"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }